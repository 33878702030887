import {SiAdobexd} from 'react-icons/si'
import {RiReactjsLine} from 'react-icons/ri'
import UdImage from '../../assets/UdImage.png'
import UFImage from '../../assets/UFlogo.png'


const data = [
    {
        id: 1, icon: <img src={UFImage} width="275" height="275" alt="UdImage" className='responsive-image'></img>, title: 'University of Florida', desc: "Iam pursuing Masters in Computer Science at University of Florida. I have started my Masters in Fall 2022 and planning to graduate in Spring 2024. My coursework includes Machine Learning, Mathematics for Intelligent Systems, Distributed Operating Systems and Principles, Analysis of Algorithms, Computer Networks, Computer Networks and Security, Cyber Physical Security Systems. My Cumulative GPA is 3.66/4.0"
    },
    {
        id: 2, icon: <img src={UdImage} width="275" height="275" alt="UFImage" className='responsive-image'></img>, title: 'NIT, Durgapur', desc: "I have completed my Bachelor's degree in Computer Science at National Institute of Technology, Durgapur in the year 2020. I have studied here for 4 years from 2016 to 2020 where my course work included Database Management Systems, Computer Networks, Algorithm Analysis, Operating systems, Object Oriented Programming, Computer Architecture and Principles e.t.c. My Cumulative GPA is 8.52/10.0"
    },
]


export default data