const data = [
    {
      id: 1,
      question: "Programming Languages",
      answer: "C, C++, C#, Javascript, Python, HTML, CSS"
    },
    {
      id: 2,
      question: "Frameworks",
      answer: "React.JS, Node.JS, ASP.NET"
    },
    {
      id: 5,
      question: "Cloud Services",
      answer: "AWS, Azure"
    },
    {
      id: 4,
      question: "Databases",
      answer: "PostgreSQL, MySQL, MSSQL"
    },
    {
      id: 3,
      question: "Other Tools",
      answer: "Postman, ORM"
    },
    {
      id: 6,
      question: "Other Courses",
      answer: " Data Structures, Algorithms, Operating Systems, DBMS, Object Oriented Programming, Agile Methodology, Software Engineering, Machine Learning, Computer Networks, Computer Networks and Security, Cyber Physical Security Systems"
    }
  ]

  export default data