import Image1 from '../../assets/project1.png'
import Image2 from '../../assets/project2.jpg'
import Image3 from '../../assets/project3.jpg'
import Image4 from '../../assets/project4.png'
import Image5 from '../../assets/project5.jpg'
import Image6 from '../../assets/project6.png'
import Image7 from '../../assets/project7.png'
import Image8 from '../../assets/project8.jpg'

const data = [
    {
        id: 1,
        category: 'Experience',
        image: Image1,
        title: "Advanced App Engineering Analyst",
        desc: "I have worked for 2 years(Sep 2020 - July 2022) as an Advanced App Engineering Analyst at Accenture, India. During my tenure at Accenture I have performed ETL processes using AWS S3 buckets, RESTful APIs, databases for data Extraction, PostgreSQL and AWS Lambda for data transformations and PostgreSQL, ORM tools for data mapping and persistence. Also, I have automated this process using AWS Lambda and Glue."
    },
    {
        id: 2,
        category: 'Experience',
        image: Image2,
        title: "Jr. Data Analyst",
        desc: "During my 3-month internship at Phycare Solutions, I served as a Jr. Data Analyst. I designed and implemented a cross-platform medical coding and billing database system using VB, Crystal Reports, SQL Server, Java, and Java-based tools. I also collaborated with other departments to develop dynamic, generic, and ad-hoc reports, ensuring data integrity and extracting valuable insights from the database."
    },
    {
        id: 3,
        category: 'Experience',
        image: Image3,
        title: "Research Intern",
        desc:"During my 3-month internship at IIIT Hyderabad in 2019, I worked under Prof. Suryakanth V. Gangashetty on the project 'Linear Prediction and MFCC Analysis for Speech Processing.' I conducted research and literature reviews on NLP techniques for speech processing, gaining a strong understanding of the concepts. I utilized MATLAB to implement these algorithms, resulting in a significant 15% improvement in accuracy when analyzing speech signals."
    },
    {
        id: 4,
        category: 'Projects',
        image: Image4,
        title: "Tweeter Clone Engine",
        desc: "I have worked on the Tweeter Clone Engine project during my first semester at University of Florida. I have modelled this Tweeter Clone Engine using WebSocket API to perform functionalities like registration, tweet, re-tweet, etc. I have developed this project in Erlang Language using Aka Model (Actors Model) which resulted in a effectuated system processing for 11,000 users with every user having a maximum of 10 tweets of string length of 20."
    },
    {
        id: 5,
        category: 'Projects',
        image: Image5,
        title: "Genetic Algorithm Approach for K-Coverage Problem",
        desc: "I have worked on this project during my bachelors where I have developed a python source code for a wireless sensor network based on a genetic algorithm approach to address all the targets with same proximity which increased the coverage by 34% by minimizing the number of sensor nodes in sensor network fulfilling the K-coverage problem of sensor nodes."
    },
    {
        id: 6,
        category: 'Projects',
        image: Image6,
        title: "Alzheimer’s Disease detection using Convolutional Neural Networks",
        desc: "I have worked on this project during my bachelors where I have built a CNN model to detect the Alzheimer’s disease using deep learning methods and trained the CNN model with a dataset (MRI images of brain) using 10 layers to achieve high accuracy. We have achieved an accuracy of 92% which helped in detecting the MRI images better."
    },
    {
        id: 7,
        category: 'Certifications',
        image: Image7,
        title: "AWS Developer Associate Certification (DVA C02) by AWS",
        desc: "I am certified as an AWS Developer Associate on August 15, 2023. I have gained knowledge on various AWS concepts such as EC2 Instances, IAM, AWS CLI, AWS ASG, AWS ELB, AWS ECS, AWS RDS, AWS Aurora, AWS Route 53, AWS S3 buckets, AWS CloudFront, AWS Elastic Beanstalk, AWS CloudFormation, AWS Lambda e.t.c."
    },
    {
        id: 8,
        category: 'Certifications',
        image: Image8,
        title: "AWS Developer Associate Certification by Udemy",
        desc: "I am certified as an AWS Developer Associate on August 15, 2023. I have gained knowledge on various AWS concepts such as EC2 Instances, IAM, AWS CLI, AWS ASG, AWS ELB, AWS ECS, AWS RDS, AWS Aurora, AWS Route 53, AWS S3 buckets, AWS CloudFront, AWS Elastic Beanstalk, AWS CloudFormation, AWS Lambda e.t.c."
    },
]


export default data