import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'
//import {PiCertificateBold} from 'react-icons/PiCertificateBold'
import { TbCertificate } from 'react-icons/tb';


const data = [
    {id: 1, icon: <FaAward/>, title: 'Experience', desc: '2+ Years Work Experience'},
    {id: 2, icon: <TbBooks/>, title: 'Projects', desc: '3 Projects'},
    {id: 3, icon: <TbCertificate/>, title: 'Certifications', desc: '2 Certifications'}
]

export default data;