import {AiOutlineInstagram} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
import {AiFillGithub} from 'react-icons/ai'

const data = [
    {id:1, link: 'https://www.instagram.com/amruthavalli_prakash/', icon: <AiOutlineInstagram/>},
    {id:2, link: 'https://twitter.com/AmruthavalliYVS', icon: <AiOutlineTwitter/>},
    {id:3, link: 'https://github.com/amruthavalliyvsr-create', icon: <AiFillGithub/>}
]

export default data;