import AboutImage from '../../assets/about.jpg'
import CV from '../../assets/cv.pdf'
import {HiDownload} from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="about">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="AboutImage" />
                </div>
            </div>
            <div className="about__right">
                <h2>About Me</h2>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p>
                    Computer Science has always been my passion. I find immense satisfaction in crafting elegant and efficient code, solving complex problems, and building innovative solutions that can make a tangible impact on people's lives.
                </p>
                <p>
                    Hi, My name is Amruthavalli Yerranagula Venkata Sai Ram. Iam a student pursuing Masters in Computer Science  at University of Florida, looking for Full time opportunities for SDE and Full Stack Developer roles. My educational journey has equipped me with a solid foundation in programming languages such as C++, Python, Java, and a proficiency in web development technologies including JavaScript, HTML, CSS, Node.js, and React.js. Additionally, I have hands-on experience with cloud computing using AWS and database management with MySQL, MsSQL, and PostgreSQL. Check out my resume below for more details!
                </p>
                <a href={CV} download className='btn primary'>Download CV <HiDownload/></a>
            </div>
        </div>
    </section>
  )
}

export default About